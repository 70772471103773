import client from '@config/client'
import API_URL from '@config/services'
import queryString from '@utils/queryString'

export const createBank = payload => {
  return client
    .post(API_URL.BANKS.POST_BANK_LIST_PK, payload)
    .then(response => response)
    .catch(error => error.response)
}

export const patchBank = (id, payload) => {
  return client
    .patch(API_URL.BANKS.PATCH_BANK_ID(id), payload)
    .then(response => response)
    .catch(error => error.response)
}

export const deleteBank = id => {
  return client
    .delete(API_URL.BANKS.DELETE_BANK_ID(id))
    .then(response => response)
    .catch(error => error.response)
}

export const connectBank = bankId => {
  return client
    .get(
      API_URL.BANKS.GET_TREASURIES(bankId) +
        '?' +
        queryString({
          redirect_uri: 'https://app2.keysy.co/account/settings/invoicing/banks'
        })
    )
    .then(response => response)
    .catch(error => error.response)
}

export const clearConnection = bankId => {
  return client
    .delete(API_URL.BANKS.DELETE_CONNECTED_USER(bankId))
    .then(response => response)
    .catch(error => error.response)
}

export const forceSync = bankId => {
  return client
    .patch(API_URL.BANKS.PATCH_FORCE_SYNC(bankId))
    .then(response => response)
    .catch(error => error.response)
}

export const getTransactionMatches = () => {
  return client
    .get(API_URL.BANKS.GET_TRANSACTION_MATCHES())
    .then(response => response)
    .catch(error => error.response)
}

export const ignoreTransaction = (transactionId, payload) => {
  return client
    .patch(API_URL.CASHFLOW.PATCH_TRANSACTION(transactionId), payload)
    .then(response => response)
    .catch(error => error.response)
}

export const matchInvoiceToTransaction = payload => {
  return client
    .post(API_URL.BANKS.POST_MATCH_TRANSACTION(), payload)
    .then(response => response)
    .catch(error => error.response)
}

export const approveMatch = (matchId, payload) => {
  return client
    .patch(API_URL.BANKS.PATCH_MATCH_TRANSACTION(matchId), payload)
    .then(response => response)
    .catch(error => error.response)
}

export const deleteMatch = matchId => {
  return client
    .delete(API_URL.BANKS.DELETE_TRANSACTION_MATCHES(matchId))
    .then(response => response)
    .catch(error => error.response)
}
